import { ActionsSocialUsers } from '../../../../../assets/components/ActionsSocialUsers'
import { franchisePageTheme } from '../styles/franchisePageTheme'
import { Cta } from './Cta'
import { useFranchiseAccountButtonData } from '../hooks/useFranchiseAccountButtonData'
import startCase from 'lodash/startCase'

export function FranchiseAccountHeaderButtonSection() {
  const { ctaText, onClick } = useFranchiseAccountButtonData()

  return (
    <div className="flex items-center gap-4">
      <Cta isSmall isDisabled={false} isLoading={false} isLight onClick={() => onClick()} className="h-[30px]">
        <ActionsSocialUsers color={franchisePageTheme.colors.fleurDeSelCaramel} size={18} />
        <span className="max-w-[120px] overflow-hidden truncate text-ellipsis !font-oswald text-sm font-bold text-fleurDeSelCaramel">
          {startCase(ctaText)}
        </span>
      </Cta>
    </div>
  )
}
