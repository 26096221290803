import clsx from 'clsx'
import { NextImage } from '../../../../common/functionality/NextImage'
import { cdnUrl } from '../../../../common/constants/s3ImageUrls'
import { FranchiseAccountHeaderButtonSection } from '../../../account/features/franchise-membership/atoms/FranchiseAccountHeaderButtonSection'
import { tabQueryParamName } from '../../../../common/constants/queryParams'
import { FranchiseNavbarNavItem } from './FranchiseNavbarNavItem'
import { Clickable } from '../../../../common/component-library/buttons/Clickable'
import { NextLink } from '../../../../common/functionality/NextLink'
import { FranchiseHamburger } from './FranchiseHamburger'
import React from 'react'
import { useWordPressFranchiseLocationsData } from '../../../account/features/franchise-membership/hooks/useWordPressFranchiseLocationsData'
import { franchiseTabs } from '../../../account/features/franchise-membership/constants/franchiseTabs'
import { useFranchiseDeploymentData } from '../../../account/features/franchise-membership/hooks/useHost/useFranchiseDeploymentData'
import { useBaseNavItems } from './hooks/useBaseNavItems'

export type FranchiseNavItem = ReturnType<typeof useFranchiseNavItems>[number]

export const useFranchiseNavItems = () => {
  const { locationLinks } = useWordPressFranchiseLocationsData()

  const { base } = useBaseNavItems()

  const mergedNavItems = React.useMemo(() => {
    if (!locationLinks.length) return base
    return base.map((x) => {
      if (x.main.content === 'Locations') {
        return { ...x, sub: locationLinks }
      }
      return x
    })
  }, [locationLinks, base])
  return mergedNavItems
}

export function EagerFranchiseNavContent() {
  const franchiseNavItems = useFranchiseNavItems()

  const { franchiseHomeRoute } = useFranchiseDeploymentData()
  return (
    <div className="relative">
      <div
        className={clsx(
          'inline-flex h-[calc(68px+env(safe-area-inset-top))] w-full items-center justify-between gap-3 bg-grayscalewhite px-3 pt-[env(safe-area-inset-top)] showFranchiseNavDropdownItems:px-5'
        )}
      >
        <div className={clsx('flex items-center gap-[11.78px] showFranchiseNavDropdownItems:gap-4')}>
          <div className="relative h-[17.676px] w-[156.876px] showFranchiseNavDropdownItems:h-6 showFranchiseNavDropdownItems:w-[213px]">
            <NextImage
              src={cdnUrl('upgrade-labs/upgrade-labs-logo-gold-axolotl-purple-text.webp ')}
              alt={'upgrade-labs-logo'}
              fill
            />
          </div>
        </div>
        <div className="hidden w-full max-w-[545px] showFranchiseNavDropdownItems:flex">
          <ul className="flex flex-1 justify-between">
            {franchiseNavItems.map((x) => (
              <FranchiseNavbarNavItem key={x.main.content} {...x} />
            ))}
          </ul>
        </div>
        <div className="hidden items-center gap-2 showFranchiseNavDropdownItems:flex">
          <FranchiseAccountHeaderButtonSection />
          <Clickable
            small
            franchisePrimary
            as={NextLink}
            elementProps={{ href: `${franchiseHomeRoute}?${tabQueryParamName}=${franchiseTabs.upgrades}` }}
          >
            <span className="whitespace-nowrap !font-oswald">Book Now</span>
          </Clickable>
        </div>
        <div className="flex gap-2 showFranchiseNavDropdownItems:hidden">
          <FranchiseAccountHeaderButtonSection />
          <FranchiseHamburger navItems={franchiseNavItems} />
        </div>
      </div>
    </div>
  )
}
