import * as React from 'react'
import { NextLink } from '../../../../common/functionality/NextLink'
import clsx from 'clsx'
import { theme } from '../../../../styles/theme'
import { franchisePageTheme } from '../../../account/features/franchise-membership/styles/franchisePageTheme'
import { useUrl } from '../../../../hooks/useUrl'
import { Collapse } from '../../../../common/style/Collapse'
import { type FranchiseNavItem } from './EagerFranchiseNavContent'
import omit from 'lodash/omit'
import noop from 'lodash/noop'

type Props = FranchiseNavItem & {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  hovering: boolean
  setHovering: React.Dispatch<React.SetStateAction<boolean>>
  isSidebar?: boolean
}

const Main = ({
  main,
  isSidebar,
  sub,
  ...rest
}: React.PropsWithChildren<Pick<FranchiseNavItem, 'main' | 'sub'> & React.HTMLAttributes<HTMLElement>> & {
  isSidebar?: boolean
}) => {
  const { content: _, ...restMain } = main

  const href = 'href' in main ? main.href : undefined
  const mainOnClick = 'onClick' in main && typeof main.onClick === 'function' ? main.onClick : noop
  return href && (!isSidebar || sub.length === 0) ? (
    <NextLink
      href={href}
      {...rest}
      onClick={(e) => {
        mainOnClick()
        rest.onClick?.(e)
      }}
      {...omit(restMain, 'href', 'onClick')}
    >
      {rest.children}
    </NextLink>
  ) : (
    <button
      {...rest}
      onClick={(e) => {
        mainOnClick()
        rest.onClick?.(e)
      }}
    >
      {rest.children}
    </button>
  )
}

export function FranchiseNavDropdown({ main, sub, hovering, setHovering, show, setShow, isSidebar }: Props) {
  const url = useUrl()
  return (
    <>
      <li className="m-0 list-none p-0">
        <Main
          isSidebar={isSidebar}
          main={main}
          sub={sub}
          className={clsx(isSidebar ? 'justify-start p-[10px]' : 'justify-center', 'flex items-center gap-2')}
          onMouseEnter={() => {
            if (!isSidebar) setShow(true)
            setHovering(true)
          }}
          onMouseLeave={() => setHovering(false)}
          onClick={() => setShow((s) => !s)}
        >
          <div
            className={clsx(
              '!font-oswald !text-xl !font-normal !transition',
              show || hovering ? '!text-dramatist' : '!text-grayscale900'
            )}
          >
            {main.content}
          </div>
          {sub.length > 0 && (
            <svg
              width="12"
              height="6"
              viewBox="0 0 12 6"
              fill="none"
              className={clsx('transition', show ? 'rotate-0' : 'rotate-180')}
            >
              <path
                className={clsx('transition')}
                d="M5.5286 0.471404L1.13807 4.86193C0.718095 5.28191 1.01554 6 1.60948 6H10.3905C10.9845 6 11.2819 5.2819 10.8619 4.86193L6.4714 0.471405C6.21106 0.211055 5.78895 0.211055 5.5286 0.471404Z"
                fill={show || hovering ? franchisePageTheme.colors.dramatist : theme.colors.grayscale900}
              />
            </svg>
          )}
        </Main>
        <ul
          className={clsx(
            isSidebar ? 'relative' : 'absolute bottom-0 left-0 translate-y-[calc(100%+13px)]',
            show || isSidebar ? 'block' : 'hidden'
          )}
        >
          <Collapse isOpen={show || !isSidebar}>
            {/* absolutely positioned white content to go between top of nav dropdown and the header nav item so the cursor doesn't hit dead space and close before reaching the dropdown */}
            {!isSidebar && <div className={clsx('absolute left-0 right-0 top-[-13px] h-[13px] bg-grayscalewhite')} />}
            <div className="bg-grayscalewhite">
              {sub.map((y) => {
                const href = 'href' in y && y.href ? y.href : ''
                const isActive = url === href
                const Component = href ? NextLink : 'button'
                return (
                  <li key={y.content}>
                    <Component
                      href={href}
                      className={clsx(
                        'group flex items-center px-6 py-4 transition',
                        isActive ? 'bg-jupiter' : 'bg-grayscalewhite hover:bg-dramatist'
                      )}
                    >
                      <span
                        className={clsx(
                          'whitespace-nowrap !font-oswald text-lg font-normal transition',
                          isActive ? 'text-yamabukiGold' : 'text-grayscale900 group-hover:text-grayscalewhite'
                        )}
                      >
                        {y.content}
                      </span>
                    </Component>
                  </li>
                )
              })}
            </div>
          </Collapse>
        </ul>
      </li>
    </>
  )
}
