import * as React from 'react'
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside'
import { FranchiseNavDropdown } from './FranchiseNavDropdown'
import { type FranchiseNavItem } from './EagerFranchiseNavContent'

type Props = FranchiseNavItem

export function FranchiseNavbarNavItem(props: Props) {
  const [show, setShow] = React.useState(false)
  const { ref } = useOnClickOutside<HTMLSpanElement>(() => setShow(false))
  const [hovering, setHovering] = React.useState(false)

  return (
    <span ref={ref} className="relative w-fit" onMouseLeave={() => setShow(false)}>
      <FranchiseNavDropdown {...props} show={show} setShow={setShow} hovering={hovering} setHovering={setHovering} />
    </span>
  )
}
