import { theme } from '../../styles/theme'
import { getCssProp } from '../../utilities/getCssProp'
import { RotatableSvgComponentProps, SvgBase } from '../common'
import { useGetShortestRotationFromOrientation } from '../hooks/useGetShortestRotationFromOrientation'

export function ActionsSocialUsers({
  size = 16,
  orientation,
  color = theme.colors.grayscale900,
  styles,
  ...rest
}: RotatableSvgComponentProps) {
  const rotation = useGetShortestRotationFromOrientation(orientation)
  const cssProp = getCssProp(styles)
  return (
    <SvgBase $size={size} viewBox="0 0 18 19" fill="none" $rotation={rotation} css={cssProp} {...rest}>
      <g clipPath="url(#clip0_1125_4349)">
        <circle cx="9" cy="5.5" r="3.25" stroke={color} strokeWidth="1.5" />
        <path
          d="M16.9875 15.9403C17.0966 16.1316 17.0758 16.3122 16.9779 16.4615C16.8736 16.6206 16.6723 16.75 16.4063 16.75H1.59437C1.32842 16.75 1.12711 16.6206 1.02274 16.4615C0.924866 16.3122 0.904117 16.1316 1.01319 15.9403C1.47049 15.1383 1.98823 14.4306 2.45959 13.9593C3.31853 13.1003 4.33825 12.419 5.46051 11.9541C6.58277 11.4893 7.7856 11.25 9.00033 11.25C10.2151 11.25 11.4179 11.4893 12.5401 11.9541C13.6624 12.419 14.6821 13.1003 15.5411 13.9593C16.0124 14.4306 16.5302 15.1383 16.9875 15.9403Z"
          stroke={color}
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1125_4349">
          <rect width={size} height={size} fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgBase>
  )
}
