'use client'
import * as React from 'react'
import { useAutoUpdatingRef } from './useAutoUpdatingRef'
import { isElement } from '../typescript/guards/isElement'

type OnClickOutside = (event: Event) => void

export function useOnClickOutside<T = HTMLDivElement>(onClickOutside: OnClickOutside, skip?: boolean) {
  const ref = React.useRef<T>(null)

  const callbackRef = useAutoUpdatingRef(onClickOutside)

  React.useEffect(() => {
    if (skip) return
    const listener = (event: Event) => {
      if (!callbackRef.current) return
      // Do nothing if clicking ref's element or descendent elements
      if (
        !ref.current ||
        (event.target && isElement(ref.current) && ref.current.contains(event.target as HTMLElement))
      ) {
        return
      }
      callbackRef.current(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [callbackRef, ref, skip])
  return { ref }
}
