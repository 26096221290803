import clsx from 'clsx'
import { LazyLoadingSpinner } from '../../../../../common/components/LazyLoadingSpinner'
import { franchisePageTheme } from '../styles/franchisePageTheme'
import { css } from '@emotion/react'

type Props = {
  onClick?: () => void
  isLoading: boolean
  isDisabled: boolean
  isLight?: boolean
  isRed?: boolean
  isSmall?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export function Cta({ onClick, isLoading, isDisabled, className, children, isLight, isRed, isSmall, ...rest }: Props) {
  const disabled = isLoading || isDisabled
  return (
    <button
      css={
        isRed && !isDisabled
          ? css`
              background-color: ${franchisePageTheme.colors.oldWine};
              transition: background-color 0.3s;
              :hover {
                background-color: ${franchisePageTheme.colors.shinshu};
              }
            `
          : null
      }
      onClick={onClick}
      disabled={disabled}
      style={{ fontFamily: franchisePageTheme.fonts.oswald }}
      className={clsx(
        isSmall ? 'px-3 py-1.5' : 'px-4 py-3',
        'whitespace-nowrap',
        isLight ? 'text-fleurDeSelCaramel' : 'text-grayscale00',
        'relative flex w-full appearance-none items-center justify-center gap-1 rounded-full font-semibold transition',
        isLight ? 'hover:bg-augustMorning' : 'hover:bg-fleurDeSelCaramel',
        isLoading ? 'cursor-progress' : isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
        disabled
          ? 'bg-grayscale000 text-grayscale100'
          : isLight
            ? 'bg-blankCanvas'
            : 'bg-raichuOrange text-grayscale00',
        disabled ? 'border border-grayscale100' : 'border border-transparent',
        className
      )}
      {...rest}
    >
      {isLoading && <LazyLoadingSpinner styles={{ position: 'absolute' }} isSmall delay={0} />}
      {children}
    </button>
  )
}
