import * as React from 'react'
import { FranchiseSidebarNavSection } from './FranchiseSidebarNavSection'
import { type FranchiseNavItem } from './EagerFranchiseNavContent'
import { NavigationClose } from '../../../../assets/components/NavigationClose'
import clsx from 'clsx'
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside'
import { useOnNavigationComplete } from '../../../../hooks/useOnNavigationComplete'
import { useBaseNavItems } from './hooks/useBaseNavItems'

type Props = { navItems: FranchiseNavItem[] }

export function FranchiseHamburger({ navItems }: Props) {
  const [isOpen, setIsOpen] = React.useState(false)

  const { ref } = useOnClickOutside<HTMLDivElement>(() => setIsOpen(false))
  useOnNavigationComplete(() => setIsOpen(false))

  const { bookNowNavItemData } = useBaseNavItems()

  const withMobileSpecificItemsAdded = React.useMemo(() => {
    const mobileItems: FranchiseNavItem[] = [{ main: bookNowNavItemData, sub: [] }]
    return mobileItems.concat(navItems)
  }, [navItems, bookNowNavItemData])
  return (
    <div ref={ref}>
      <button className="group flex h-[30px] w-[40px] items-center justify-center" onClick={() => setIsOpen((s) => !s)}>
        {isOpen ? (
          <NavigationClose
            className="group"
            groupClassname="!stroke-yamabukiGold group-hover:!stroke-hotterButter group-active:!stroke-orangePepper"
          />
        ) : (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect
              x="4"
              y="5"
              width="16"
              height="2"
              rx="1"
              className="!fill-yamabukiGold group-hover:!fill-hotterButter group-active:!fill-orangePepper"
            />
            <rect
              x="4"
              y="11"
              width="16"
              height="2"
              rx="1"
              className="!fill-yamabukiGold group-hover:!fill-hotterButter group-active:!fill-orangePepper"
            />
            <rect
              x="4"
              y="17"
              width="16"
              height="2"
              rx="1"
              className="!fill-yamabukiGold group-hover:!fill-hotterButter group-active:!fill-orangePepper"
            />
          </svg>
        )}
      </button>
      <div className={clsx(isOpen ? 'block' : 'hidden')}>
        <ul
          style={{ boxShadow: '0px 2px 8px 0px rgba(23, 47, 59, 0.25)' }}
          className="absolute right-0 top-[53px] w-[260px] max-w-full bg-grayscalewhite"
        >
          {withMobileSpecificItemsAdded.map((x) => (
            <FranchiseSidebarNavSection key={x.main.content} {...x} />
          ))}
        </ul>
      </div>
    </div>
  )
}
