import { franchiseServiceQueryParamName, tabQueryParamName } from '../../../../../common/constants/queryParams'
import { externalFranchiseUrlBase, externalOwnFranchiseUrlBase } from '../../../../../common/constants/routes'
import { newTabLinkProps } from '../../../../../utilities/newTabLinkProps'
import { franchiseTabs } from '../../../../account/features/franchise-membership/constants/franchiseTabs'
import { useFranchiseDeploymentData } from '../../../../account/features/franchise-membership/hooks/useHost/useFranchiseDeploymentData'

type ItemBase = { content: string } & ({ href: string | null } | { onClick: () => void })

type Main = { main: ItemBase; sub: ItemBase[] }

export const useBaseNavItems = () => {
  const { franchiseHomeRoute } = useFranchiseDeploymentData()

  const bookNowNavItemData = {
    content: 'Book Now',
    href: `${franchiseHomeRoute}?${tabQueryParamName}=${franchiseTabs.upgrades}`,
  } as const

  const base: Main[] = [
    { main: { content: 'Home', href: `${externalFranchiseUrlBase}/` }, sub: [] },
    {
      main: { content: 'Get Started', href: null },
      sub: [
        bookNowNavItemData,
        {
          content: 'First Upgrade',
          href: `${franchiseHomeRoute}?${tabQueryParamName}=${franchiseTabs.upgrades}&${franchiseServiceQueryParamName}=Intro+Tour`,
        },
        { content: 'Memberships', href: `${franchiseHomeRoute}?${tabQueryParamName}=${franchiseTabs.memberships}` },
        { content: 'Packages', href: `${franchiseHomeRoute}?${tabQueryParamName}=${franchiseTabs.packages}` },
      ],
    },
    {
      main: { content: 'Technologies', href: `${externalFranchiseUrlBase}/` },
      sub: [
        { content: 'AI Adaptive Bike™', href: `${externalFranchiseUrlBase}/technologies/ai-adaptive-bike/` },
        { content: 'AI Cheat Machine™', href: `${externalFranchiseUrlBase}/technologies/ai-cheat-machine/` },
        { content: 'Big Squeeze™', href: `${externalFranchiseUrlBase}/technologies/big-squeeze/` },
        {
          content: 'Brain Upgrade: Neurofeedback™',
          href: `${externalFranchiseUrlBase}/technologies/brain-upgrade-neurofeedback/`,
        },
        { content: 'Cryotherapy', href: `${externalFranchiseUrlBase}/technologies/cryotherapy/` },
        { content: 'Metabolic Trainer™', href: `${externalFranchiseUrlBase}/technologies/metabolic-trainer/` },
        { content: 'PEMF', href: `${externalFranchiseUrlBase}/technologies/pemf/` },
        { content: 'REDcharger™', href: `${externalFranchiseUrlBase}/technologies/redcharger/` },
        { content: 'The Vibe Plate™', href: `${externalFranchiseUrlBase}/technologies/the-vibe-plate/` },
      ],
    },
    { main: { content: 'Locations', href: `${externalFranchiseUrlBase}/locations` }, sub: [] },
    { main: { content: 'Franchise', href: externalOwnFranchiseUrlBase, ...newTabLinkProps }, sub: [] },
  ]
  return { base, bookNowNavItemData }
}
