import { useAccount } from '../../../../auth/hooks/useAccount'
import { useLogInState } from '../../../../auth/authState'
import React from 'react'
import { useSignUpCompoundFranchiseLocationData } from './useSignUpCompoundFranchiseLocationData'
import { franchiseTabs } from '../constants/franchiseTabs'
import { useSetFranchiseAccountModal } from './useSetFranchiseAccountModal'
import { useDevice } from '../../../../../hooks/useDevice'

export function useFranchiseAccountButtonData(isSignUp?: boolean) {
  const compoundFranchiseLocationData = useSignUpCompoundFranchiseLocationData()

  const { isTabletDown } = useDevice()
  const [, setLogInState] = useLogInState()

  const { isAuthenticated } = useAccount()

  const { setFranchiseAccountModal, name, firstName, lastName } = useSetFranchiseAccountModal()

  const mobileName = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
  return React.useMemo(
    () => ({
      ctaText: isAuthenticated ? (isTabletDown ? mobileName : name) : isSignUp ? 'Sign Up' : 'Log In',
      onClick: (modalTab: franchiseTabs = franchiseTabs.upgrades) =>
        isAuthenticated
          ? setFranchiseAccountModal(modalTab)
          : setLogInState((s) => ({
              ...s,
              isLoggingIn: true,
              loginOptions: {
                realize_form: 'ulf-signup',
                ...compoundFranchiseLocationData,
                ...(isSignUp && { screen_hint: 'signup' }),
              },
            })),
    }),
    [
      setLogInState,
      isSignUp,
      isAuthenticated,
      name,
      compoundFranchiseLocationData,
      setFranchiseAccountModal,
      mobileName,
      isTabletDown,
    ]
  )
}
